@media (orientation: landscape) {

    .Header {
        height: 10vh;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;        
    }

    .MainTitle {
        font-size: 7vw;        
        margin-top: 1vh;
    }

    .Description {
        font-size: 2vw;        
        width: 80%;
    }

    .Subtitle {
        font-size: 3vw;        
        width: 80%;
        text-align: left;
    }

    .Centered {
        text-align: center;
    }

    .UnderlinedWithMargins {
        text-decoration: underline;
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .EmailText {
        font-size: 2vw;
        text-align: center;
        text-decoration: underline;        
    }

    .EmailGoogleGroupText {
        font-size: 2vw;
        text-align: center;
        text-decoration: underline;
    }

    .ContactTitle {
        padding-left: 1vw;
        padding-right: 1vw;
        font-size: 3vw;
        text-align: center;
        text-decoration: underline;
        padding-top: 5vh;
    }

    .ContactText {
        padding-left: 1vw;
        padding-right: 1vw;
        font-size: 2vw;        
        text-align: center;
    }
    
}

@media (orientation: portrait) {

    .Header {
        height: 12vh;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;        
    }

    .MainTitle {
        font-size: 9vw;
        margin-top: 1vh;
    }

    .Description {
        font-size: 5vw;        
        width: 90%;
    }

    .Subtitle {
        font-size: 7vw;        
        width: 90%;
        text-align: left;
    }

    .Centered {
        text-align: center;
    }

    .UnderlinedWithMargins {
        text-decoration: underline;        
        margin-top: 2vh;
        margin-bottom: 1vh;
    }

    .EmailText {
        font-size: 5vw;
        text-align: center;
        text-decoration: underline;
    }

    .EmailGoogleGroupText {
        font-size: 3.5vw;
        text-align: center;
        text-decoration: underline;
    }

    .ContactTitle {
        padding-left: 1vw;
        padding-right: 1vw;
        font-size: 5vw;
        text-align: center;
        text-decoration: underline;
        padding-top: 5vh;
    }

    .ContactText {
        padding-left: 1vw;
        padding-right: 1vw;
        font-size: 5vw;
        text-align: center;
    }
    
}